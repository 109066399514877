import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { InitialAppLoader } from "./loadingComponents";
import "./static/vendor/css/font-awesome.min.css";
import "./imports.scss";

const PrivateRoute = lazy(() => import("./PrivateRoute"));
const LoginComponent = lazy(() => import("./components/Login/LoginComponent"));
const LogoutComponent = lazy(() => import("./components/LogoutComponent"));
const PasswordResetComponent = lazy(() => import("./components/Login/PasswordResetComponent"));
const PasswordResetConfirmComponent = lazy(() =>
    import("./components/Login/PasswordResetConfirmComponent")
);

const SentryRoute = Sentry.withSentryRouting(Route);

window.smcGlobal = {};

export function MainApp() {
    return (
        <Suspense fallback={<InitialAppLoader />}>
            <BrowserRouter>
                <Switch>
                    <SentryRoute path="/password_reset/" exact component={PasswordResetComponent} />
                    <SentryRoute
                        path="/password_reset/confirm/:user_id/:token/"
                        exact
                        component={PasswordResetConfirmComponent}
                    />
                    <SentryRoute path="/logout" component={LogoutComponent} />
                    <SentryRoute path="/login" component={LoginComponent} />
                    <SentryRoute path="/" component={PrivateRoute} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
}

ReactDOM.render(<MainApp />, document.getElementById("app-container"));
